#navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-left:5vw;
    margin-right:5vw;
    color: #65748D;

}

.navbar-name {
    display: flex;
    align-items: center;

}

.navbar-name h1 {
    margin-right: auto;

}

.navbar-social {
    display: flex;
    margin-left: auto;

}

.navbar-social a {
    margin-left:20px;
    color: #65748D;

}

