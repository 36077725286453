@import url('https://fonts.googleapis.com/css2?family=Amiko&family=Karla&family=Overlock&display=swap');

:root {
  --font-family: 'Hibiscus Sans', sans-serif;
}

@font-face {
  font-family: var(--font-family);
  src: local('Hibiscus Sans'), url(./assets/HibiscusSans-Regular.otf) format('opentype');
}

body {
  font-family: var(--font-family), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  /*
  font-family: 'Karla', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  */
  line-height:1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: 40px 40px;
  background-image: radial-gradient(circle, rgba(101, 116, 141, 0.46) 1px, rgba(0, 0, 0, 0) 1px);
  background-color: #F4F5F7;
  color: #65748D;
}

li{
  font-family: var(--font-family), sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
a:link{
  text-decoration: none;
  font-weight:bold;
  color: #D0A9A6;
}

a:visited {
  color: #D0A9A6;
}

li:not(:last-child) {
  margin-bottom: 10px;
}

.screen{
  width:100vw;
  height:100vh;
  overflow: hidden;
}

.name{
  height:5vh;
}



.canvas-container img {
  position: absolute;
  max-height: 300px;
  width: auto;

}

.layers-panel {
  float: left;
  width: 30%;
  margin-left: 5vw;

}

.container {
  width: auto;
  height: 80vh;
  margin-left: 5vw;
  margin-right: 5vw;

}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}


::-webkit-scrollbar {
  width: 7px;
}

.panel-container{
  width: 20rem;
  height: 35.8125rem;
  border-radius: 0.625rem;
  background: #EDEEF0;
  position: absolute;
  box-shadow: 0 0 2px rgba(0,0,0,0.2);
  margin-left:3vw;

}
.layers{
  margin-top:-10px;
  margin-bottom:10px;
  top:0;
  align-items: center;

}

.button-div{
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.button{
  margin: 10px 0;
  width: 300px;
  height: 3.125rem;
  border-radius: 0.625rem;
  background-color: rgba(255, 255, 255, 0.46);
  border: none;
  margin-bottom:-2px;
  font-family: var(--font-family), -apple-system, BlinkMacSystemFont, sans-serif;
  /*font-family: 'Amiko', -apple-system, BlinkMacSystemFont, sans-serif;*/
  color:#6A788E;

}

.button:hover{
  background: #EEE7E8;

}
.button:focus{
  background:  #E9E3E4;

}

.button.active {
  background: #E9E3E4 ;

}

.canvas-panel {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.canvas-panel img {
  max-height: 300px;
  width: auto;
  margin-bottom: 10px;
}

.canvas-image{
  position: absolute;
  bottom:0;
  justify-content: center;
  align-items: center;

}

p.panel-text{
  margin-left:10px;
  margin-bottom: 10px;
}

p.canvas-text{
  margin-left:10px;
  margin-bottom: 10px;
}

.canvas-big-container{
  justify-content: center;
  align-items: center;
}

.tab-content-container {
  display: inline-block;
  margin-left: 20vw;
  width: 20rem;
}

.tab-content {
  min-width: 45vw;
  background: rgba(255, 255, 255, 0.46);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  margin-left: -5vw;
  padding: 15px 25px;
  border-radius: 0.625rem;
  max-height: 34rem;
  overflow-y: auto;
}

.tab-text {
  padding-top: -10vh;
  align-items: center;
  max-height: 100%;
  display: inline-block;
  height: auto;
  margin-left: 10vw;
  margin-right: 50px;
}





.list-tab-content {
  height:auto;
  max-height:68vh;
  margin-left:10vw;
  margin-right:50px;
  overflow-y: auto;
  border-radius: 0.625rem;

}

.box{

  background: rgba(255, 255, 255, 0.46);
  box-shadow: 0 0 2px rgba(0,0,0,0.2);
  overflow-y: clip;
  word-wrap: normal;
  border-radius: 0.625rem;
  min-width: 40vw;
  min-height:32vh;
  justify-self: start;
  padding-top:5px;
  padding-bottom:10px;
  padding-left:20px;
  padding-right:20px;
  margin:10px;

}

.box img {
  display: block;
  margin: 0 auto;
}
::-webkit-scrollbar{
  background-color: rgb(169, 192, 203, 0.14);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(169, 192, 203, 0.9);
  border-radius: 20px;
  border: 3px white;
}


@media (max-width: 867px) {
  *{
    text-wrap: normal;
  }
  .panel-container {
    width: 100%;
    max-height:30vh;
    padding-top: 5px;
    padding-bottom: 0;
    margin-left: 5px;
    margin-right: 5px;
    position: static;
  }


  .button-div{
    display:flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-height:20vh;
  }

  .canvas-big-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .canvas-container {
    position: relative;
    text-align: center;
  }

  .canvas-container img {
    max-height: 200px;
    width: auto;
    display:none;
  }
  .canvas-image{
    margin-left:200px;
    display:none;

  }

  .canvas-text{
    display:none;
  }
  .canvas-text-container{
    padding-top:30px;
    overflow-x:hidden;
    text-wrap: normal;
  }

  .tab-content-container {
    position: static;
    margin-top: 20px;
    margin-left: -5px;
    margin-right:-5px;
    margin-bottom:20px;
    height:100%;
    width:auto;
    text-wrap: normal;

  }

  .tab-content {
    min-width: 80vw;
    margin-left: 10px;
    margin-right: 10px;
    padding: 16px;
    background: rgba(255, 255, 255, 0.46);
    box-shadow: 0 0 2px rgba(0,0,0,0.2);
    border-radius: 0.625rem;

  }

  .list-tab-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .box {
    width: calc(100% - 20px);
    margin: 10px;
    box-sizing: border-box;
  }

  .box img {
    max-width: 100%;
    height: auto;
  }

  .canvas-container img {
    max-height: 200px;
    width: auto;
  }

  .tlist-tab-content {
    all:unset;
  }

  .tbox img{
    max-width: 70vw;
    height: auto;
    width: auto;
    display: block;
    margin: 30px;
  }

  .tbox {
    background: rgba(255, 255, 255, 0.46);
    box-shadow: 0 0 2px rgba(0,0,0,0.2);
    overflow-y: clip;
    word-wrap: normal;
    border-radius: 0.625rem;
    max-width:77vw;
  }

  .ttab{
    all: unset;
  }

  .ttab-text{
    all:unset;

  }
}


.tlist-tab-content {
  max-height: 35.8125rem;
  min-width: 45vw;
  margin-left: -5vw;
  margin-right:50px;
  overflow-y: auto;
  border-radius: 0.625rem;
}

.tbox {
  overflow-y: clip;
  word-wrap: normal;
  max-width: 80vw;
  min-height:30vh;
  justify-self: start;
  padding-top:5px;
  padding-bottom:20px;
  padding-left:20px;
  padding-right:20px;
  margin-bottom:10px;
  background: rgba(255, 255, 255, 0.46);
  box-shadow: 0 0 2px rgba(0,0,0,0.2);
  border-radius: 0.625rem;
}

.tbox h4{
  margin-top: 10px;
  margin-bottom:0;
  padding:0;
}

.ttab{
  margin-left:10vw;
  width: 64vw;

}

.tbox img{
  max-height: 40vh;
  width: auto;
  display: block;
  margin: 0 auto;
}

.tool{
  color: rgb(101,116,141, 0.5);
  border: solid rgb(101,116,141, 0.5) 1px;
  border-radius: 14px;
  display:inline-block;
  margin:3px;
  padding: 2px 8px 2px 8px;
  font-size:14px;
  justify-content: center;
  align-content: center;

}